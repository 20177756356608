import { createContext, useState } from "react";
const LocationContext = createContext({});

export const LocationProvider = ({ children }) => {
  const [location, setLocation] = useState("All");
  return (
    <LocationContext.Provider value={{ location, setLocation }}>
      {children}
    </LocationContext.Provider>
  );
};

export default LocationContext;
