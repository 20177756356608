import { useRef, useState, useEffect } from "react";
import useAuth from "../hooks/useAuth";
import { useNavigate, useLocation } from "react-router-dom";
import { Form, Container, Col, Button, Row, Card } from "react-bootstrap";
import axios from "../api/axios";
import styles from "./LoginPage.module.css";
import { faArrowsRotate } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const LOGIN_URL = "/api/auth/login";

const LoginPage = () => {
  const { setAuth, persist, setPersist } = useAuth();
  const navigate = useNavigate();
  const location = useLocation();
  const from = location.state?.from || "/";

  const userRef = useRef();
  const errRef = useRef();

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [errMsg, setErrMsg] = useState("");
  const [loading, isLoading] = useState(false);

  useEffect(() => {
    userRef.current.focus();
    setPersist(true);
  }, []);
  useEffect(() => {
    setErrMsg("");
  }, [email, password]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      isLoading(true);
      const response = await axios.post(
        LOGIN_URL,
        JSON.stringify({ email, password }),
        {
          headers: { "Content-Type": "application/json" },
          withCredentials: true,
        }
      );
      const accessToken = response?.data?.accessToken;
      const roles = response?.data?.roles;

      setAuth({ email, roles, accessToken });
      setEmail("");
      setPassword("");
      isLoading(false);
      navigate(from, { replace: true });
    } catch (err) {
      if (!err.response) {
        setErrMsg("No server response");
        console.log(err);
      } else if (err.response?.statue === 400) {
        console.log(err.response);
        setErrMsg("Missing Username or Password");
      } else if (err.response?.status === 401) {
        setErrMsg("Unauthorized");
        console.log(err);
      } else {
        setErrMsg("Login Failed");
        console.log(err.response);
      }
      isLoading(false);
      errRef.current.focus();
    }
  };

  const togglePersist = () => {
    setPersist((prev) => !prev);
  };
  useEffect(() => {
    localStorage.setItem("persist", persist);
  }, [persist]);

  return (
    <>
      <div className={[styles.loginLayout]}>
        <div className={styles.loginCard}>
          <Form onSubmit={handleSubmit}>
            <div className="mb-md-5 mt-md-4 pe-5">
              <p
                ref={errRef}
                className={errMsg ? styles.errmsg : styles.offscreen}
                aria-live="assertive"
              >
                {errMsg}
              </p>
              <h2 className={styles.loginTitle}>Login</h2>
              <p className="text-black-75 text-center mb-5">
                Please enter your login and password!
              </p>
              <div className="mb-4">
                <label className="form-label" htmlFor="typeEmailX">
                  Email
                </label>
                <input
                  type="email"
                  ref={userRef}
                  id="typeEmailX"
                  autoComplete="false"
                  className="form-control form-control-lg"
                  onChange={(e) => setEmail(e.target.value)}
                  value={email}
                  required
                />
              </div>
              <div className=" mb-3">
                <label className="form-label" htmlFor="password">
                  Password
                </label>
                <input
                  type="password"
                  id="password"
                  autoComplete="false"
                  className="form-control form-control-lg"
                  onChange={(e) => setPassword(e.target.value)}
                  required
                />
              </div>
              <div className="mb-5">
                <input
                  type="checkbox"
                  id="persist"
                  onChange={togglePersist}
                  checked={persist}
                />
                <label htmlFor="persist" className="mx-2">
                  Remember me
                </label>
              </div>
              <button
                size="lg"
                variant="primary"
                className={
                  !loading ? styles.loginButton : styles.activeLoginButton
                }
                type="submit"
                disabled={loading}
              >
                {loading ? (
                  <>
                    <FontAwesomeIcon
                      icon={faArrowsRotate}
                      size="lg"
                      className={styles.spinner}
                    />{" "}
                    Logging In
                  </>
                ) : (
                  "Sign In"
                )}
              </button>
            </div>
          </Form>
        </div>
      </div>
    </>
  );
};

export default LoginPage;
