import "./Loader.scss";

const Loader = () => {
  return (
    <>
      <div className="loader">
        <div className="circle"></div>
        <div className="circle"></div>
        <div className="circle last"></div>
        <div className="circle clear"></div>
        <div className="circle"></div>
        <div className="circle last"></div>
        <div className="circle clear"></div>
        <div className="circle "></div>
        <div className="circle last"></div>
      </div>
    </>
  );
};

export default Loader;
