import { useEffect, useState, useMemo, useRef } from "react";
import { useParams } from "react-router-dom";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import PageTitle from "../components/PageTitle";
import useAxiosPrivate from "../hooks/useAxiosPrivate";
import uuid from "react-uuid";
import { ChartList } from "../components/ChartList";
import FencePickets from "../components/FencePickets";
import Loader from "../components/loader/Loader";
import useLocation from "../hooks/useLocation";

const SpeciesPage = () => {
  const [speciesData, setSpeciesData] = useState();
  const [speciesDetail, setSpeciesDetail] = useState([]);
  const [loading, setLoading] = useState(true);
  const [cate, setCate] = useState("");

  const [tabs, setTabs] = useState([]);
  const { SpeciesCode, DetailType } = useParams();
  const [key, setKey] = useState(0);
  const axiosPrivate = useAxiosPrivate();
  const { location } = useLocation();

  const [color, setColor] = useState("Yellow");
  const [title, setTitle] = useState("Missing Title");

  const firstEffectExecuted = useRef(false);
  useEffect(() => {
    console.log("SpeciesPage re-rendered");
  });
  let num = 0;
  useEffect(() => {
    const controller = new AbortController();
    const getSpeciesData = async () => {
      try {
        console.log("Step One");
        console.log("Detail Type:  ", DetailType);
        //console.log("YOUR selected location is: ", location);
        Promise.all([
          // need to add a type to detail table in SQL.
          // this will help to tell if it is a modular or list
          // right now it is uisng species code to figure that out
          // the server checks if it is specicalty or picket, if not than it assumes it is modular.
          axiosPrivate.get(`/api/species/dynamic`, {
            params: {
              species: SpeciesCode,
              category: cate,
              location: location,
              detailType: DetailType,
            },
            signal: controller.signal,
          }),
          axiosPrivate.get(`/api/species/details`, {
            signal: controller.signal,
          }),
          axiosPrivate.get("/api/species/tabs", {
            params: { species: SpeciesCode },
            signal: controller.signal,
          }),
        ]).then(([dynamicData, detail, tabs]) => {
          setSpeciesData(dynamicData.data);
          setSpeciesDetail(detail.data.recordset);

          setTabs(tabs.data);
          console.log("Tabs: ", tabs.data);
          setLoading(false);
        });
      } catch (error) {
        setSpeciesData("");
        console.log("error on selected species pages");
        console.log(error);
      }
    };
    getSpeciesData();
    firstEffectExecuted.current = true;
  }, [SpeciesCode]);

  useEffect(() => {
    speciesDetail.map((sd) => {
      if (sd.ddSpeciesCode == SpeciesCode) {
        setColor(sd.ddColor);
        setTitle(sd.ddTitle);
      }
    });
  }, [speciesDetail]);
  useEffect(() => {
    const controller = new AbortController();
    const getSpeciesData = async () => {
      try {
        console.log("Step Two");
        //console.log("your selected location is: ", location);

        await axiosPrivate
          .get(`/api/species/category`, {
            params: {
              species: SpeciesCode,
              category: cate,
              location: location,
              detailType: DetailType,
            },
            signal: controller.signal,
          })
          .then((dynamicData) => {
            setSpeciesData(dynamicData.data);
            console.log("Dynamic Data: ", dynamicData.data);
            setLoading(false);
          });
      } catch (error) {
        console.log("error on selected species pages");
        console.log(error);
      }
    };
    console.log("current Cate: ", cate);

    if (firstEffectExecuted.current && cate !== "") {
      getSpeciesData();
    }

    // if (firstEffectExecuted.current) {
    // } else {
    // }
  }, [cate, location]);

  useEffect(() => {
    console.log(tabs);
    if (tabs?.length) {
      //maybe this setCate is the issue...
      setCate(tabs[0].Category);
      console.log("cateTTTT: ", tabs[0].Category);
      setKey(0);
    }
  }, [tabs]);
  const memoizedSpeciesData = useMemo(() => {
    return <ChartList color={color} speciesData={speciesData} />;
  }, [color, speciesData]);
  return (
    <>
      <PageTitle
        key={Math.random()}
        Title={title}
        Color={`#${color}`}
        CompanyName={"Solidwood Forest Ltd."}
      />

      {loading ? (
        <Loader />
      ) : (
        <>
          {tabs?.length ? (
            <Tabs
              activeKey={key}
              id="layoutTabs"
              className="mb-3"
              onClick={(e) => setCate(e.target.innerHTML)}
              onSelect={(k) => setKey(k)}
            >
              {tabs.map((tab) => (
                <Tab key={uuid()} eventKey={num++} title={tab.Category}>
                  {cate === tab.Category ? memoizedSpeciesData : null}
                </Tab>
              ))}
            </Tabs>
          ) : (
            <>
              <FencePickets speciesData={speciesData} />
            </>
          )}
        </>
      )}
    </>
  );
};

export default SpeciesPage;
