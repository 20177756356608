import { useState, useEffect, useRef, React } from "react";
import { useNavigate } from "react-router-dom";
import useLogout from "../../hooks/useLogout";
import Button from "react-bootstrap/Button";

const AutoLogout = ({ handleMouseMove, mousetime }) => {
  const [showPopup, setShowPopup] = useState(false);
  const [countdown, setCountdown] = useState(120);
  const [intervalId, setIntervalId] = useState(null);
  const logout = useLogout();
  const navigate = useNavigate();
  const countdownRef = useRef(countdown);

  useEffect(() => {
    if (!handleMouseMove) {
      setIntervalId(
        setInterval(() => {
          setShowPopup(true);
        }, 1800000) // 30 minutes in milliseconds 1800000
      );
      return () => clearInterval(intervalId);
    } else {
      clearInterval(intervalId);
    }
  }, [handleMouseMove]);

  useEffect(() => {
    countdownRef.current = countdown;
  }, [countdown]);

  useEffect(() => {
    const logUserOut = async () => {
      await logout("Signed out due to inactivity");
    };
    if (countdownRef.current === 0) {
      setShowPopup(false);
      setCountdown(120);
      logUserOut();
    }
  }, [logout]);

  useEffect(() => {
    if (showPopup) {
      clearInterval(intervalId);
    }
  }, [showPopup]);

  useEffect(() => {
    if (showPopup) {
      const interval = setInterval(() => {
        setCountdown((countdown) => countdown - 1);
      }, 1000);

      return () => clearInterval(interval);
    }
  }, [showPopup]);

  // useEffect(() => {
  //   // call handleMouseMove whenever the mouse is moved
  //   // window.addEventListener("mousemove", handleMouseMove);
  //   //return () => window.removeEventListener("mousemove", handleMouseMove);
  //   console.log("mouse movement detected within AutoLogout.js");
  // }, [handleMouseMove]);

  const handleStayLoggedIn = () => {
    console.log("blah");

    setShowPopup(false);
    setCountdown(120);
    // setIntervalId(
    //   setInterval(() => {
    //     setShowPopup(true);
    //   }, 10000) // 30 minutes in milliseconds 1800000
    // );
  };

  const handleLogout = async () => {
    await logout("sign-out button on the timeout message");
    setShowPopup(false);
    navigate("/login");
  };

  return (
    <div>
      {showPopup && (
        <>
          <div
            style={{
              position: "fixed",
              top: 0,
              left: 0,
              width: "100%",
              height: "100%",
              backgroundColor: "rgba(0, 0, 0, 0.5)",
              zIndex: "9998",
            }}
          ></div>
          <div
            style={{
              position: "fixed",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              backgroundColor: "#fff",
              padding: "20px",
              border: "0px solid #000",
              borderRadius: "5px",
              zIndex: "9999",
            }}
          >
            <p>You will be logged out in {countdown} seconds.</p>
            <Button variant="danger" onClick={handleLogout}>
              Logout
            </Button>
            <Button
              variant="success"
              style={{ marginLeft: "3.5rem" }}
              onClick={handleStayLoggedIn}
            >
              Stay logged in
            </Button>
          </div>
        </>
      )}

      {/* The rest of your app code goes here */}
    </div>
  );
};

export default AutoLogout;
