//This should be replacing ChatCard.js
import { faDiceSix } from "@fortawesome/free-solid-svg-icons";
import { useEffect, useState } from "react";
import Card from "react-bootstrap/Card";
import Container from "react-bootstrap/Container";
import Table from "react-bootstrap/Table";
import uuid from "react-uuid";
import styles from "./ChartCard.module.css";
import "react-tooltip/dist/react-tooltip.css";
import { Tooltip } from "react-tooltip";

let num = 0;
const ChartModular = ({ size, details = [], color }) => {
  //console.log("Details: ", details);
  console.log("ChartModu lar: ", details);
  return (
    <>
      <Container>
        <Card className={[styles.cardSize, "shadow p-3 mb-5 bg-body"]}>
          <Table striped bordered>
            <thead>
              <tr>
                <th style={{ backgroundColor: `#${color}` }}>{details[0]}</th>
                {details[2].map((g) => (
                  <th key={uuid()}>{g}</th>
                ))}
              </tr>
              <tr
                style={{
                  backgroundColor: `#${color}`,
                }}
              >
                <th>{details[1][0][3] ? details[1][0][3] : "SYP"}</th>

                {details[2].map((g) => (
                  <th key={uuid()}>BDLS</th>
                ))}
              </tr>
            </thead>
            <tbody>
              {details[1].map((length) => (
                <tr key={uuid()} className={styles.rowHeight}>
                  <td key={uuid()}>{length[0]}</td>
                  {length[2].map((displayBdl) => (
                    <>
                      <td>
                        {displayBdl.DisplayBdls === 0 ? (
                          "CALL"
                        ) : displayBdl.DisplayBdls ===
                          "skip" ? null : displayBdl.MaxTrue === 1 ? (
                          <>
                            <button
                              data-tooltip-id={`displayBtn${num}`}
                              data-tooltip-content={displayBdl.pieces}
                              data-tooltip-place="top"
                              className={styles.BLDSBtn}
                            >
                              {displayBdl.DisplayBdls}+
                            </button>
                            <Tooltip
                              id={`displayBtn${num++}`}
                              variant="success"
                            />
                          </>
                        ) : (
                          <>
                            <button
                              data-tooltip-id={`displayBtn${num}`}
                              data-tooltip-content={displayBdl.pieces}
                              data-tooltip-place="top"
                              className={styles.BLDSBtn}
                            >
                              {displayBdl.DisplayBdls}
                            </button>
                            <Tooltip
                              style={{ opacity: "1", fontSize: "15px" }}
                              id={`displayBtn${num++}`}
                              variant="success"
                            />
                          </>
                        )}
                      </td>
                    </>
                  ))}
                </tr>
              ))}
            </tbody>
          </Table>
        </Card>
      </Container>
    </>
  );
};

export { ChartModular };
