import { useEffect, useState, useRef } from "react";
import {
  faAngleDown,
  faPhone,
  faFileLines,
  faArrowRight,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import styles from "./CompanyInfo.module.css";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
const CompanyInfo = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [showProgressBar, setShowProgressBar] = useState(false);
  const [progress, setProgress] = useState(0);
  const containerRef = useRef(null);
  const axiosPrivate = useAxiosPrivate();
  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);
const handleOpenBorchure = async () => {
    console.log("brochure clicked");
    const controller = new AbortController();

    setShowProgressBar(true);

    axiosPrivate
      .get(`/api/docs/brochure`, {
        responseType: "arraybuffer", // Change responseType to arraybuffer
        signal: controller.signal,
        onDownloadProgress: (progressEvent) => {
          const percentCompleted = Math.round(
            (progressEvent.loaded * 100) / progressEvent.total
          );
          setProgress(percentCompleted);
        },
      })
      .then((response) => {
        setShowProgressBar(false);

        const fileName = "SOLIDWOOD FOREST - BROCHURE.pdf";
        const fileData = new Uint8Array(response.data); // Create a new Uint8Array with the response data

        // Create a temporary anchor element
        const anchor = document.createElement("a");
        anchor.href = URL.createObjectURL(
          new Blob([fileData], { type: "application/pdf" })
        );
        anchor.download = fileName;

        // Append the anchor to the document, click it, and then remove it
        document.body.appendChild(anchor);
        anchor.click();
        document.body.removeChild(anchor);
      })
      .catch((error) => {
        setShowProgressBar(false);
        console.log(error);
      });
  };
  //this is a test method
  const handleDownload = async () => {
    try {
      const response = await axiosPrivate.get(`/api/docs/brochure`, {
        responseType: "blob",
      });

      const fileUrl = window.URL.createObjectURL(response.data);

      const link = document.createElement("a");
      link.href = fileUrl;
      link.setAttribute("download", "brochure.pdf"); // set the file name
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (error) {
      console.error(error);
    }
  };
  const handleMenuClick = () => {
    setIsOpen(!isOpen);
  };
  const handleClickOutside = (event) => {
    if (containerRef.current && !containerRef.current.contains(event.target)) {
      setIsOpen(false);
    }
  };
  return (
    <div ref={containerRef}>
      <button className={styles.dropdownBtn} onClick={handleMenuClick}>
        COMPANY:{" "}
        <FontAwesomeIcon icon={faAngleDown} size="sm" className="pe-2" />
      </button>

      {isOpen && (
        <div className={styles.dropdownMenu}>
          <div>
            <FontAwesomeIcon icon={faArrowRight} size="sm" className="pe-2" />{" "}
            <a
              className={styles.dropdownLink}
              href="https://www.solidwoodforest.com"
              target="_blank"
            >
              solidwoodforest.com
            </a>
          </div>
          <div>
            <FontAwesomeIcon icon={faFileLines} size="sm" className="pe-2" />{" "}
            <label className={styles.labelHover} onClick={handleOpenBorchure}>
              Company Brochure
            </label>
            {showProgressBar && (
              <div className={styles.progressBar}>
                <progress
                  id="progressBar"
                  value={progress}
                  max="100"
                ></progress>
              </div>
            )}
          </div>
          <label>
            <FontAwesomeIcon icon={faPhone} size="sm" className="pe-2" />{" "}
            281-351-9109
          </label>
        </div>
      )}
    </div>
  );
};

export default CompanyInfo;
