import { Col, Container, Row } from "react-bootstrap";
import { ChartCard } from "./ChartCard";
import { ChartModular } from "./ChartModular";
import uuid from "react-uuid";
const ChartList = ({ speciesData, color }) => {
  //console.log("speciesDataChartList: ", speciesData);
  return (
    <>
      <Container fluid>
        <Row className="justify-content-center">
          {speciesData.map((d) => (
            <Col key={uuid()} sm="auto">
              <ChartModular color={color} details={d} />
            </Col>
          ))}
        </Row>
      </Container>
    </>
  );
};

export { ChartList };
