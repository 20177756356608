import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Bar } from "react-chartjs-2";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);
const Chartie = ({ dataView, labelTitle }) => {
  // console.log("dataview is: ", dataView);
  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: "top",
      },
      title: {
        display: true,
        text: labelTitle,
      },
    },
    scales: {
      y: {
        ticks: {
          precision: 0,
        },
      },
    },
  };
  const labels = dataView.species;
  const data = {
    labels,
    datasets: [
      {
        label: labelTitle,
        data: dataView.totalCounts,
        backgroundColor: "rgba(255, 99, 132, 0.5)",
      },
    ],
  };
  return (
    <div style={{ marginTop: "20px", width: "800px", height: "500px" }}>
      <div style={{ position: "absolute", marginTop: "30px" }}>Views</div>
      <Bar options={options} data={data} />
      <div
        style={{
          alignItems: "center",
        }}
      >
        Species Name
      </div>
    </div>
  );
};

export default Chartie;
