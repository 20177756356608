import { useState } from "react";
import styles from "./LocationPicker.module.css";

const LocationItem = ({
  name,
  companyId,
  setSelected,
  onCheckboxClick,
  isChecked,
}) => {
  const menuItemClick = (e) => {
    setSelected(e.target.id);
    onCheckboxClick(companyId, !isChecked);
  };

  const handleCheckboxChange = (e) => {
    //this may be causing a duplicate issue.
    onCheckboxClick(companyId, e.target.checked);
  };

  return (
    <li className={styles.item}>
      <div
        className={styles.itemContainer}
        id={companyId}
        onClick={menuItemClick}
      >
        <div className={styles.itemName}>{name}</div>
        <input
          className={styles.dropdownChkbx}
          type="checkbox"
          readOnly
          checked={isChecked}
          onChange={handleCheckboxChange}
        />
      </div>
    </li>
  );
};
export default LocationItem;
