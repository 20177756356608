import { useEffect } from "react";
import Accordion from "react-bootstrap/Accordion";
import Table from "react-bootstrap/Table";
import uuid from "react-uuid";

let num = 1;
const FencePickets = ({ speciesData }) => {
  //convert buffer to base64
  //console.log("ListData: ", speciesData);
  useEffect(() => {
    console.log("speciesData is updated");
  }, [speciesData]);
  console.log("FencePickets rendered");
  const arrayBufferToBase64 = (buffer) => {
    let binary = "";
    let bytes = [].slice.call(new Uint8Array(buffer));
    bytes.forEach((b) => (binary += String.fromCharCode(b)));
    return window.btoa(binary);
  };

  const reset = () => {
    num = 1;
    return null;
  };
  return (
    <Accordion alwaysOpen>
      {speciesData.map((sd) => (
        <Accordion.Item eventKey={uuid()}>
          <Accordion.Header>{sd.Category}</Accordion.Header>

          <Accordion.Body>
            <Table striped bordered hover>
              <tbody>
                {sd.rows.map((detail) => (
                  <>
                    <tr>
                      <td
                        style={{
                          textAlign: "center",
                          minWidth: "35px",
                          width: "100px",
                        }}
                      >
                        {num++}
                      </td>
                      <td style={{ textAlign: "left" }}>
                        {!detail.Descrip ? detail.SurfaceDesc : detail.Descrip}
                      </td>
                      {detail.PictureData && (
                        <td style={{ textAlign: "left" }}>
                          {!detail.PictureData ? null : detail.PictureData.data
                              .length >= 5 ? (
                            <img
                              src={`data:image/jpg;base64,${arrayBufferToBase64(
                                detail.PictureData.data
                              )}`}
                            />
                          ) : null}
                        </td>
                      )}
                    </tr>
                  </>
                ))}
                {reset()}
              </tbody>
            </Table>
          </Accordion.Body>
        </Accordion.Item>
      ))}
    </Accordion>
  );
};

export default FencePickets;
