import React from "react";
import styles from "./LocationPicker.module.css";

const SelectedLocations = ({ array, setArray, setLocation, locations }) => {
  const handleRemoveLocation = (companyId) => {
    setArray((prevArray) => prevArray.filter((id) => id !== companyId));
    setLocation((prevLocation) =>
      prevLocation
        .split(", ")
        .filter((id) => id !== companyId)
        .join(", ")
    );
  };

  return (
    <div className={styles.selectedLocations}>
      <ul className={styles.selectedLocationsList}>
        {array.map((companyId, index) => {
          // Find the location object with the matching companyId
          const location = locations.find((loc) => loc.CompanyID === companyId);

          // If location is found, display its name
          return (
            location && (
              <li key={index} className={styles.selectedLocationItem}>
                {location.name}
                <button
                  className={styles.removeLocationButton}
                  onClick={() => handleRemoveLocation(companyId)}
                >
                  x
                </button>
              </li>
            )
          );
        })}
      </ul>
    </div>
  );
};

export default SelectedLocations;
