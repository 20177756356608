import { SpeciesCard } from "./SpeciesCard";
import { useNavigate } from "react-router-dom";
import styles from "./SpeciesList.module.css";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";

const SpeciesList = ({
  species,
  onClickSpecies = () => {},
  speciesActionName,
  onSpeciesAction,
}) => {
  const history = useNavigate();

  return (
    <Container fluid>
      <Row className="justify-content-center">
        {species.map((s) => (
          <Col key={s.ddID} sm="auto">
            <SpeciesCard
              onCardClicked={onClickSpecies}
              species={s}
              iconUrl={"/icons/tree_green.png"}
              actionName={speciesActionName}
              onAction={onSpeciesAction}
            />
          </Col>
        ))}
      </Row>
    </Container>
  );
};

export { SpeciesList };
