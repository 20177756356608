import { useState, useEffect } from "react";
import { Form, Container, Row, Col, Button } from "react-bootstrap";
import PageTitle from "./PageTitle";
import useAxiosPrivate from "../hooks/useAxiosPrivate";
import useAuth from "../hooks/useAuth";

const EMAIL_REGEX =
  /^([a-zA-Z0-9_\-\.]+)@([a-zA-Z0-9_\-\.]+)\.([a-zA-Z]{2,5})$/;
const PWD_REGEX =
  /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*()]).{8,24}$/;
const PasswordReset = () => {
  const [btnDisabled, setBtnDisabled] = useState(true);
  const [email, setEmail] = useState("");
  const [validEmail, setValidEmail] = useState(false);
  const [emailFocus, setEmailFocus] = useState(false);
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [matchPassword, setMatchPassword] = useState("");
  const [validPassword, setValidPassword] = useState(false);
  const [pwdMatchFocus, setPwdMatchFocus] = useState(false);
  const [pwdFocus, setPwdFocus] = useState(false);

  const axiosPrivate = useAxiosPrivate();
  const { auth } = useAuth();

  const submit = async (e) => {
    e.preventDefault();
    setBtnDisabled(true);
    try {
      const response = await axiosPrivate.post(
        "/api/user/resetpassword",
        {
          email,
          password,
          confirmPassword,
        },
        {
          signal: AbortController.signal,
        }
      );
      //console.log(response);
      if (response.status === 200) {
        alert("Password reset successful");
        setPassword("");
        setConfirmPassword("");
        setEmail("");
      }
    } catch (error) {
      console.log(error);
      if (error.response.status === 400) {
        alert("That email is not registered");
      } else {
        alert("Password reset failed");
      }
      setBtnDisabled(false);
    }
    setBtnDisabled(false);
  };

  const passwordCheck = () => {
    // verify password is valid
    if (PWD_REGEX.test(password)) {
      setPwdFocus(true);
    }
  };
  useEffect(() => {
    setValidPassword(PWD_REGEX.test(password));
    setMatchPassword(password === confirmPassword);
  }, [password, confirmPassword]);
  useEffect(() => {
    setValidEmail(EMAIL_REGEX.test(email));
  }, [email]);
  useEffect(() => {
    setBtnDisabled(!validEmail || !validPassword || !matchPassword);
  }, [validEmail, validPassword, matchPassword]);
  return (
    <>
      <Container>
        <Form onSubmit={submit}>
          <Row>
            <Col></Col>
            <Col>
              <PageTitle SubTitle={"Password reset"} />
              <Form.Control
                type="email"
                placeholder="Enter email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                onFocus={() => setEmailFocus(true)}
              />
              <Form.Text
                className={
                  emailFocus && validEmail ? "text-success" : "text-danger"
                }
              >
                {emailFocus && emailFocus && validEmail
                  ? "email is valid"
                  : emailFocus
                  ? "Email is not valid"
                  : null}
              </Form.Text>
            </Col>
          </Row>
          <Row>
            <Col></Col>
            <Col>
              <Form.Control
                autoComplete="false"
                type="password"
                placeholder="New Password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                onFocus={() => setPwdFocus(true)}
                onBlur={() => passwordCheck}
              />
              {pwdFocus && !validPassword ? (
                <>
                  <Form.Text className="text-danger">
                    Must include uppercase and lowercase letters, a number and a
                    special character
                  </Form.Text>
                  <br></br>
                  <Form.Text className="text-danger">
                    Must be 8 to 24 characters
                  </Form.Text>
                </>
              ) : null}
            </Col>
          </Row>
          <Row>
            <Col></Col>
            <Col>
              <Form.Control
                autoComplete="false"
                type="password"
                placeholder="Confirm New Password"
                value={confirmPassword}
                onChange={(e) => setConfirmPassword(e.target.value)}
                onFocus={() => setPwdMatchFocus(true)}
              />

              <Form.Text
                className={
                  pwdMatchFocus && matchPassword && validPassword
                    ? "text-success"
                    : "text-danger"
                }
              >
                {pwdMatchFocus && matchPassword && validPassword
                  ? "Passwords match and are valid"
                  : pwdMatchFocus
                  ? "passsword do not match"
                  : null}
              </Form.Text>
            </Col>
          </Row>
          <Row>
            <Col></Col>
            <Col>
              <Button
                className="float-end"
                disabled={btnDisabled}
                variant="primary"
                type="submit"
              >
                Reset Password
              </Button>
            </Col>
          </Row>
        </Form>
      </Container>
    </>
  );
};

export default PasswordReset;
