import { useState, useEffect } from "react";
import { Container, Table } from "react-bootstrap";
import PageTitle from "./PageTitle";
import useAxiosPrivate from "../hooks/useAxiosPrivate";
import useAuth from "../hooks/useAuth";
import uuid from "react-uuid";
const UserList = (props) => {
  const { setListUpdated, listUpdated } = props;
  const axiosPrivate = useAxiosPrivate();
  const [listUsers, setListUsers] = useState([]);
  const { auth } = useAuth();
  useEffect(() => {
    console.log("listUpdated UserList: ", listUpdated);
    const getUserList = async () => {
      try {
        const response = await axiosPrivate.get("/api/user/list");
        setListUsers(response.data);
        console.log("user list: ", response.data);
      } catch (error) {
        console.log(error);
      }
    };
    if (listUpdated) {
      getUserList();
      setListUpdated(false);
    }
  }, [listUpdated]);
  return (
    <>
      <Container className="mt-5">
        <PageTitle SubTitle={"User List"} />
        <Table striped bordered hover>
          <thead>
            <tr>
              <th>First Name</th>
              <th>Last Name</th>
              <th>Email</th>
              <th>Company</th>
              <th>Role</th>
              <th>Locked</th>
              <th>Created</th>
            </tr>
          </thead>
          <tbody>
            {listUsers.map((user) => (
              <tr key={uuid()}>
                <td>{user.firstName}</td>
                <td>{user.lastName}</td>
                <td>{user.email}</td>
                <td>{user.cmName}</td>
                <td>{user.usrRole}</td>
                <td>{user.locked}</td>
                <td>{user.createDate}</td>
              </tr>
            ))}
          </tbody>
        </Table>
      </Container>
    </>
  );
};

export default UserList;
