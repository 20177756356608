import styles from "./SpeciesCard.module.css";
import Card from "react-bootstrap/Card";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
const SpeciesCard = ({
  species: {
    ddID,
    ddType,
    ddSpeciesCode,
    ddDetailType,
    iconUrl,
    ddTitle,
    ddSubTitle,
    ddDescription,
  },
  onCardClicked,
  actionName,
  onAction,
}) => {
  const blah = () => {
    console.log("sadfasd");
  };
  return (
    <>
      <Container>
        <Row className="justify-content-md-center ">
          <Col sm="auto" className="p-3">
            <Card
              onClick={() => onCardClicked(ddSpeciesCode, ddDetailType)}
              className={[styles.cardContainer, "shadow p-3 mb-5 bg-body"]}
            >
              <Card.Body className="m-3">
                <Row className={styles.cardRow}>
                  <Col xs="auto">
                    <Card.Img
                      src={"/icons/tree_green.png"}
                      style={{ width: "20px", height: "40px" }}
                    />
                  </Col>
                  <Col sm="auto">
                    <Card.Title>{ddTitle}</Card.Title>
                    <Card.Subtitle className="mb-2 text-muted py-2">
                      {ddSubTitle}
                    </Card.Subtitle>
                  </Col>
                  <Card.Text className={styles.textBody}>
                    {ddDescription}
                  </Card.Text>
                </Row>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export { SpeciesCard };
