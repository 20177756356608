import axios from "../api/axios";
import useAuth from "./useAuth";

const useLogout = () => {
  const { setAuth } = useAuth();

  const logout = async (signoutType) => {
    console.log("sign out tpye: ", signoutType);
    setAuth({});
    try {
      await axios("/api/auth/logout", {
        params: {
          signouttype: signoutType,
        },
        withCredentials: true,
      });
    } catch (err) {
      console.log(err);
    }
  };
  return logout;
};
export default useLogout;
