import { useState } from "react";
import { Navbar, Nav, NavDropdown, Button } from "react-bootstrap";
import { useNavigate, Link } from "react-router-dom";
import {
  faPencil,
  faChartColumn,
  faArrowRightFromBracket,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import useAuth from "../hooks/useAuth";
import useLogout from "../hooks/useLogout";
import SideMenu from "./SideMenu";
import LocationSelector from "./LocationSelector";
import LocationPicker from "./location/LocationPicker";
import styles from "./Navbar.module.css";
import CompanyInfo from "./details/CompanyInfo";
const NavBar = (props) => {
  // let { visible = false } = props;
  const [isHidden, setIsHidden] = useState(false);
  const [isLeftNavVisible, setIsLeftNavVisible] = useState(false);
  const navigate = useNavigate();
  const logout = useLogout();
  const signOut = async () => {
    await logout("Signout Button Nav bar");
    navigate("/login");
  };
  const { auth } = useAuth();
  //console.log("auth", auth);
  const isLoggedIn = auth?.accessToken ? true : false;
  const closeLeftNav = () => {
    setIsLeftNavVisible(false);
  };
  return (
    <>
      <Navbar
        collapseOnSelect
        expand="md"
        bg="light"
        variant="light"
        sticky="top"
        className="shadow pe-5"
      >
        {/*// button to hide menu. maybe in future will be used to hide menu on mobile devices or desktops
         <div
          onClick={() => (isHidden ? setIsHidden(false) : setIsHidden(true))}
        >
          <FontAwesomeIcon icon={faBars} size="lg" className="mx-4" />
        </div> */}
        <Navbar.Brand className="mx-5" as={Link} to={"/"} href="/">
          <img src="/icons/logo.png" width="85" height="77.469" />
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
        <Navbar.Collapse id="responsive-navbar-nav">
          <Nav className="me-auto">
            {isLoggedIn ? <LocationPicker /> : null}
          </Nav>

          <Nav>
            {isLoggedIn
              ? [
                  auth.roles.includes(5150) ? (
                    <NavDropdown
                      title="Administration"
                      className="mx-5"
                      key={1}
                    >
                      <NavDropdown.Item
                        className="text-dark"
                        as={Link}
                        to={"/views/admin"}
                      >
                        <FontAwesomeIcon
                          icon={faPencil}
                          size="lg"
                          className="pe-2"
                        />
                        Create User Account
                      </NavDropdown.Item>
                      <NavDropdown.Item
                        className="text-dark"
                        as={Link}
                        to={"/views/reports"}
                      >
                        <FontAwesomeIcon
                          icon={faChartColumn}
                          size="lg"
                          className="pe-2"
                        />
                        Reports
                      </NavDropdown.Item>
                    </NavDropdown>
                  ) : null,
                  <div className={styles.numberBtnBlock}>
                    <Button key={2} variant="danger" onClick={signOut}>
                      <FontAwesomeIcon
                        icon={faArrowRightFromBracket}
                        size="lg"
                        className="pe-2"
                      />
                      Sign Out
                    </Button>
                    <div className={styles.numberLabel}>
                      <CompanyInfo />
                    </div>
                  </div>,
                ]
              : [
                  <Nav.Link key={3} as={Link} to={"login"} href="login">
                    Login
                  </Nav.Link>,
                ]}
          </Nav>
        </Navbar.Collapse>
      </Navbar>
      {isLoggedIn ? <SideMenu isVisible={isLeftNavVisible} /> : null}
    </>
  );
};

export default NavBar;
// <label className={styles.numberLabel}>281-351-9109</label>
