import { useEffect, useState } from "react";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import PageTitle from "./PageTitle";

import useAuth from "../hooks/useAuth";
import useAxiosPrivate from "../hooks/useAxiosPrivate";
import { InputGroup } from "react-bootstrap";

const EMAIL_REGEX =
  /^([a-zA-Z0-9_\-\.]+)@([a-zA-Z0-9_\-\.]+)\.([a-zA-Z]{2,5})$/;
const PWD_REGEX =
  /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*()]).{8,24}$/;
const CreateUser = (props, children) => {
  const { setListUpdated, listUpdated } = props;
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [company, setCompnay] = useState("");
  const [companyName, setCompanyName] = useState("");
  const [role, setRole] = useState("");
  const [email, setEmail] = useState("");
  const [validEmail, setValidEmail] = useState(false);
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [matchPassword, setMatchPassword] = useState("");
  const [validPassword, setValidPassword] = useState(false);

  const [pwdFocus, setPwdFocus] = useState(false);
  const [pwdMatchFocus, setPwdMatchFocus] = useState(false);
  const [emailFocus, setEmailFocus] = useState(false);
  const [submitBtn, setSubmitBtn] = useState(false);
  const [listCompanies, setListCompanies] = useState([]);
  const axiosPrivate = useAxiosPrivate();
  const { auth } = useAuth();

  const handleSelect = (e) => {
    setCompnay(e.target.value);
    setCompanyName(e.target.value);
  };
  const handleRoleSelect = (e) => {
    setRole(e.target.value);
  };
  const submit = async (e) => {
    e.preventDefault();
    setSubmitBtn(false);
    try {
      const response = await axiosPrivate.post(
        "/api/user/create",
        { firstName, lastName, email, password, company, role },
        {
          signal: AbortController.signal,
        }
      );
      console.log(response);
      if (response.status === 200) {
        alert("User created successfully!");
        setListUpdated(true);
        setFirstName("");
        setLastName("");
        setEmail("");
        setPassword("");
        setConfirmPassword("");
        setCompnay("");
        setCompanyName("");
        setRole("");
      } else {
        alert("User creation failed!");
      }
    } catch (err) {
      console.log("Create user err", err);
      alert("User creation failed! \n Dulpicate email address!");
      setSubmitBtn(true);
    }
  };
  useEffect(() => {
    setValidPassword(PWD_REGEX.test(password));
    setMatchPassword(password === confirmPassword);
  }, [password, confirmPassword]);
  useEffect(() => {
    setValidEmail(EMAIL_REGEX.test(email));
  }, [email]);
  useEffect(() => {
    if (
      validEmail &&
      matchPassword &&
      validPassword &&
      firstName &&
      lastName &&
      company &&
      role
    ) {
      setSubmitBtn(true);
    } else {
      setSubmitBtn(false);
    }
  }, [
    validEmail,
    matchPassword,
    validPassword,
    firstName,
    lastName,
    company,
    role,
  ]);
  useEffect(() => {
    const getCompanies = async () => {
      try {
        const response = await axiosPrivate.get("/api/user/companies");
        /// console.log("company List: ", response.data);
        setListCompanies(response.data);
      } catch (err) {
        console.log("list company error: ", err);
      }
    };

    getCompanies();
  }, []);
  return (
    <Container>
      <PageTitle Title={"Create a New User Account"} />
      <Form onSubmit={submit}>
        <Row>
          <Col>
            <Form.Label>First Name</Form.Label>
            <Form.Control
              type="input"
              placeholder="First Name"
              value={firstName}
              onChange={(e) => setFirstName(e.target.value)}
            />
          </Col>
          <Col>
            <Form.Label>Last Name</Form.Label>
            <Form.Control
              type="input"
              placeholder="Last Name"
              value={lastName}
              onChange={(e) => setLastName(e.target.value)}
            />
          </Col>
        </Row>
        <Row>
          <Col>
            <Form.Label>Company</Form.Label>
            <InputGroup>
              <Form.Control
                onChange={(e) => handleSelect(e)}
                placeholder="Search company"
                list="companies"
                value={companyName}
              ></Form.Control>
            </InputGroup>
            <datalist id="companies">
              {listCompanies?.length
                ? listCompanies.map((item) => (
                    <option
                      value={item.cmId}
                      id={item.cmId}
                      key={item.cmId}
                    ></option>
                  ))
                : "no results found"}
            </datalist>
          </Col>
          <Col>
            <Form.Label>Role</Form.Label>
            <Form.Select value={role} onChange={handleRoleSelect}>
              <option hidden>- Select -</option>
              <option>Standard</option>
              <option>Admin</option>
            </Form.Select>
          </Col>
        </Row>
        <Row>
          <Col>
            <Form.Label>Email address</Form.Label>
            <Form.Control
              type="email"
              placeholder="Enter email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              onFocus={() => setEmailFocus(true)}
            />
            <Form.Text
              className={
                emailFocus && validEmail ? "text-success" : "text-danger"
              }
            >
              {emailFocus && emailFocus && validEmail
                ? "email is valid"
                : emailFocus
                ? "Email is not valid"
                : null}
            </Form.Text>
          </Col>
        </Row>
        <Row>
          <Col>
            <Form.Group className="mb-3" controlId="formBasicPassword">
              <Form.Label>Password</Form.Label>
              <Form.Control
                autoComplete="false"
                type="password"
                placeholder="Password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                onFocus={() => setPwdFocus(true)}
                onBlur={() => setPwdFocus(false)}
              />
            </Form.Group>
            {pwdFocus && !validPassword ? (
              <>
                <Form.Text className="text-danger">
                  Must include uppercase and lowercase letters, a number and a
                  special character
                </Form.Text>
                <br></br>
                <Form.Text className="text-danger">
                  Must be 8 to 24 characters
                </Form.Text>
              </>
            ) : null}
          </Col>
          <Col>
            <Form.Group className="mb-3" controlId="formBasicConfirmPassword">
              <Form.Label>Confirm Password</Form.Label>
              <Form.Control
                autoComplete="false"
                type="password"
                placeholder="confirm"
                value={confirmPassword}
                onChange={(e) => setConfirmPassword(e.target.value)}
                onFocus={() => setPwdMatchFocus(true)}
              />
              <Form.Text
                className={
                  pwdMatchFocus && matchPassword && validPassword
                    ? "text-success"
                    : "text-danger"
                }
              >
                {pwdMatchFocus && matchPassword && validPassword
                  ? "Passwords match and are valid"
                  : pwdMatchFocus
                  ? "passsword do not match"
                  : null}
              </Form.Text>
            </Form.Group>
          </Col>
        </Row>

        <Button
          variant="primary"
          type="submit"
          disabled={!submitBtn ? true : false}
        >
          Submit
        </Button>
      </Form>
    </Container>
  );
};

export default CreateUser;
