import { useState, useEffect } from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Bar } from "react-chartjs-2";
import useAxiosPrivate from "../hooks/useAxiosPrivate";
import Chartie from "../components/reports/Chartie";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import PageTitle from "../components/PageTitle";
import { Container } from "react-bootstrap";
import UserDetails from "../components/reports/UserDetails";
import SpeciesCateReportALL from "../components/reports/SpeciesCateReportALL";

//get data user data.
// created date, last login, last logout,
// show which species was accessed by the user
const ReportPage = () => {
  const axiosPrivate = useAxiosPrivate();
  const [details, setDetails] = useState();
  const [speciess, setSpeciess] = useState();
  const [locations, setLocations] = useState();
  const [selectedLocation, setSelectedLocation] = useState();
  const [selectedSpeci, setSelectedSpeci] = useState("");
  const [categoryLabels, setCategoryLabels] = useState([]);
  const [categoryViews, setCategoryViews] = useState([]);
  const [labels, setLabels] = useState([]);
  const [totalViews, setTotalViews] = useState([]);

  useEffect(() => {
    const controller = new AbortController();
    const getSpeciesuserdetails = async () => {
      try {
        Promise.all([
          axiosPrivate.get("/api/report/details", {
            signal: controller.signal,
          }),
          axiosPrivate.get(`/api/report/speciesList`, {
            signal: controller.signal,
          }),
          axiosPrivate.get("/api/species/locations", {
            signal: controller.signal,
          }),
        ]).then(([detailsResults, speciesResults, locationResults]) => {
          setDetails(detailsResults.data);
          setSpeciess(speciesResults.data.recordset);
          setLocations(locationResults.data);
          console.log("report species: ", speciess);
        });
      } catch (error) {}
    };
    getSpeciesuserdetails();
  }, []);

  const handleSelected = (e) => {
    setSelectedSpeci(e.target.value);
  };
  const handleSelectedLocation = (e) => {
    if (e.target.value === "allLocations") {
      let allLocation = locations
        .map((location) => location.CompanyID)
        .join(", ");
      setSelectedLocation(allLocation);
    } else {
      setSelectedLocation(e.target.value);
    }
  };
  return (
    <Container>
      <PageTitle Title="Reports" />
      <Tabs defaultActiveKey="Overview" id="tabss" className="mb-3" fill>
        <Tab eventKey="Overview" title="Overview">
          <Container>
            {details?.map((d) => (
              <Chartie
                title="tests"
                dataView={d}
                labelTitle="Species Total Views"
              />
            ))}
            <select
              style={{ marginRight: "25px" }}
              onChange={handleSelectedLocation}
            >
              <option hidden>Select Location</option>
              <option value="allLocations" key="allLocations">
                All
              </option>
              {locations?.map((loc) => (
                <option value={loc.CompanyID} key={loc.CompanyID}>
                  {loc.name}
                </option>
              ))}
            </select>
            <select onChange={handleSelected}>
              <option hidden>Select Species</option>
              {speciess?.map((s) => (
                <option value={s.ddSpeciesCode} key={s.ddTitle}>
                  {s.ddTitle}
                </option>
              ))}
            </select>
            <SpeciesCateReportALL
              selectedSpecies={selectedSpeci}
              selectedLocation={selectedLocation}
            />
          </Container>
        </Tab>

        <Tab eventKey="Users" title="Users">
          <UserDetails speciesList={speciess} locations={locations} />
        </Tab>
      </Tabs>
    </Container>
  );
};

export default ReportPage;

//const getSpeciesuserdetails = async () => {
//     try {
//       const response = await axiosPrivate.get("/api/report/details");
//       console.log("response is: ", response);
//     } catch (error) {}
//     try {
//       const [species, category] = await Promise.all([
//         axiosPrivate.get("/api/report/speciesdetails", {
//           signal: controller.signal,
//         }),
//         axiosPrivate.get("/api/report/categorydetails", {
//           signal: controller.signal,
//         }),
//       ]);
//       setLabels(species.data.species);
//       setTotalViews(species.data.totalCounts);
//       setDetails(category.data);
//       console.log(
//         "categoires: ",
//         details[0].categories.map((c) => c.category)
//       );
//       console.log(
//         "count: ",
//         details[0].categories.map((c) => c.total_count)
//       );
//       setCategoryViews(details[0].categories.map((c) => c.total_count));
//       setCategoryLabels(details[0].categories.map((c) => c.category));
//       console.log(categoryLabels);
//     } catch (err) {
//       console.error(err);
//       // Handle the error here.
//     }
//   };
//   getSpeciesuserdetails();
