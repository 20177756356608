import { useEffect, useState, useMemo, useRef } from "react";
import { Route, Routes } from "react-router-dom";
import Layout from "./components/Layout";
import DetailsPage from "./pages/DetailsPage";
import NavBar from "./components/Navbar";
import SideMenu from "./components/SideMenu";
import SpeciesPage from "./pages/SpeciesPage";
import LoginPage from "./pages/LoginPage";
import RequireAuth from "./components/RequireAuth";
import PersistLogin from "./components/PersistLogin";
import AdminPage from "./pages/AdminPage";
import ReportPage from "./pages/ReportPage";
import useAuth from "./hooks/useAuth";
import "./App.css";
import MouseTracker from "./components/watcher/MouseTracker";
import AutoLogout from "./components/alerts/AutoLogout";
import WindowCloser from "./components/watcher/WindowCloser";

const ROLES = {
  User: 2001,
  Editor: 1984,
  Admin: 5150,
};
function App() {
  const [lastMouseMove, setLastMouseMove] = useState(false);
  const { auth, authReport } = useAuth();
  const [isLeftNavVisible, setIsLeftNavVisible] = useState(false);

  const [isMouseMoving, setIsMouseMoving] = useState(true);
  const timeoutIdRef = useRef(null);

  const handleMouseMove = useMemo(() => {
    setIsMouseMoving(true);
    clearTimeout(timeoutIdRef.current);
    timeoutIdRef.current = setTimeout(() => {
      setIsMouseMoving(false);
    }, 1000);
  }, []);

  useEffect(() => {
    window.addEventListener("mousemove", handleMouseMove);

    return () => {
      window.removeEventListener("mousemove", handleMouseMove);
      clearTimeout(timeoutIdRef.current);
    };
  }, [handleMouseMove, isMouseMoving]);

  useEffect(() => {
    auth?.accessToken ? setIsLeftNavVisible(true) : setIsLeftNavVisible(false);
  }, [auth]);

  const mainContentClass = isLeftNavVisible
    ? "main-content left-nav-visible"
    : "main-content left-nav-collapsed";

  const doSomthing = useMemo(() => {
    setIsMouseMoving(false);
  }, []);
  return (
    <>
      {auth?.accessToken ? (
        <AutoLogout handleMouseMove={isMouseMoving} />
      ) : null}
      <MouseTracker />
      <NavBar />
      <div className={mainContentClass}>
        <Routes>
          <Route path="/" element={<Layout />}>
            <Route path="/login" element={<LoginPage />} />

            <Route element={<PersistLogin />}>
              <Route
                element={
                  <RequireAuth allowedRoles={[ROLES.User, ROLES.Admin]} />
                }
              >
                <Route path="/" element={<DetailsPage />} />

                <Route
                  path="/species/:SpeciesCode/:DetailType"
                  element={<SpeciesPage />}
                />
              </Route>
              <Route element={<RequireAuth allowedRoles={[ROLES.Admin]} />}>
                <Route path="/views/admin" element={<AdminPage />} />
                <Route path="/views/reports" element={<ReportPage />} />
              </Route>
            </Route>
          </Route>
        </Routes>
      </div>
    </>
  );
}

export default App;
