import styles from "./SideMenu.module.css";
import Nav from "react-bootstrap/Nav";
import { faTree } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import useDetails from "../hooks/useDetails";
import { useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { LinkContainer } from "react-router-bootstrap";
import useAxiosPrivate from "../hooks/useAxiosPrivate";
import useAuth from "../hooks/useAuth";
import { Container } from "react-bootstrap";
import { useLocation } from "react-router-dom";
const SideMenu = (props) => {
  const { isHidden = false, setIsHidden, isVisible } = props;
  const visibilityClass = isVisible ? "d-block" : "d-none";
  const navigate = useNavigate();
  const { auth, presist } = useAuth();
  const isLoggedIn = auth?.accessToken ? true : false;
  const { details, setDetails } = useDetails();

  const axiosPrivate = useAxiosPrivate();

  const location = useLocation();

  const isActive = (path) => {
    return location.pathname === path;
  };

  useEffect(() => {
    const controller = new AbortController();
    const getSpeciesDetails = async () => {
      try {
        const response = await axiosPrivate.get("/api/species/details", {
          signal: controller.signal,
        });
        setDetails(response.data.recordset);
        console.log(response.data.recordset);
      } catch (err) {
        console.log(err);
      }
    };
    getSpeciesDetails();
    return () => {
      controller.abort();
    };
  }, []);

  return (
    <>
      {details?.length && isLoggedIn ? (
        <Nav
          className={[
            styles.sidebar,
            `flex-column d-md-block ${visibilityClass}`,
          ]}
        >
          {details.map((sl) => (
            <LinkContainer
              to={`/species/${sl.ddSpeciesCode}/${sl.ddDetailType}`}
              exact
            >
              <Nav.Link
                key={sl.ddID}
                eventKey="link-1"
                className={
                  isActive(`/species/${sl.ddSpeciesCode}/${sl.ddDetailType}`)
                    ? styles.active
                    : styles.notActive
                }
              >
                <FontAwesomeIcon icon={faTree} size="lg" className="pe-2" />
                {sl.ddTitle}
              </Nav.Link>
            </LinkContainer>
          ))}
        </Nav>
      ) : null}
    </>
  );
};

export default SideMenu;
{
  /* <>
      {details?.length && isLoggedIn ? (
        <Nav
          className={[styles.sidebar, "d-flex flex-column flex-shrink-0 p-3"]}
        >
          <div>
            {details.map((sl) => (
              <Nav.Link
                key={sl.ddID}
                as={Link}
                to={`/species/${sl.ddSpeciesCode}/${sl.ddDetailType}`}
                href={`/species/${sl.ddSpeciesCode}/${sl.ddDetailType}`}
                eventKey="link-1"
                className="mt-1"
              >
                <FontAwesomeIcon icon={faTree} size="lg" className="pe-2" />
                {sl.ddTitle}
              </Nav.Link>
            ))}
          </div>
        </Nav>
      ) : null}
    </> */
}
