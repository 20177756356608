import React, { useState, useRef, useEffect } from "react";
import { faAngleDown } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import LocationItem from "./LocationItem";
import SelectedLocations from "./SelectedLocations";
import styles from "./LocationPicker.module.css";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import useLocation from "../../hooks/useLocation";

const LocationPicker = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [locations, setLocations] = useState([]);
  const [selected, setSelected] = useState();
  const [items, setItems] = useState([]);
  const [isChecked, setIsChecked] = useState(false);
  const [array, setArray] = useState([]);
  const [showSelected, setShowSelected] = useState(false);
  const containerRef = useRef(null);
  const axiosPrivate = useAxiosPrivate();
  const { location, setLocation } = useLocation();
  const handleButtonClick = () => {
    setIsOpen(!isOpen);
    console.log("array is4: ", array);
    console.log(array.join(", "));
  };

  const handleCheckboxClick = (companyId, isChecked) => {
    if (isChecked) {
      // Check if the companyId is already in the array
      if (!array.includes(companyId)) {
        setArray([...array, companyId]);
        setLocation([...array, companyId].join(", "));
        setShowSelected(true);
      }
    } else {
      setArray(array.filter((id) => id !== companyId));
      console.log("array is2: ", array);
      setLocation(array.filter((id) => id !== companyId).join(", "));
    }
    setIsChecked(isChecked);
  };
  useEffect(() => {
    const getLocations = async () => {
      try {
        const response = await axiosPrivate.get("api/species/locations");
        console.log("Location Data: ", response.data);
        setLocations(response.data);
      } catch (err) {
        console.log("Location list error: ", err);
      }
    };
    getLocations();
  }, []);

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  //need to setup a default location.
  //maybe add support for it to auto default section when all are unchecked.
  useEffect(() => {
    if (locations.length > 0) {
      // Initialize array with the ID of the first location
      const initialArray = locations.map((loc) => loc.CompanyID);

      // Set the initial state of array and location
      setArray(initialArray);
      setLocation(initialArray.join(", "));

      // Show the selected locations if array is not empty
      setShowSelected(true);

      // Set the isChecked state based on whether the first location is selected
      setIsChecked(true);
      console.log("setLocation equals: ", location);
    }
  }, [locations]);

  const handleClickOutside = (event) => {
    if (containerRef.current && !containerRef.current.contains(event.target)) {
      setIsOpen(false);
    }
  };

  return (
    <>
      <div ref={containerRef}>
        <button className={styles.dropdownBtn} onClick={handleButtonClick}>
          Locations:{" "}
          <FontAwesomeIcon icon={faAngleDown} size="sm" className="pe-2" />
        </button>

        {isOpen && (
          <ul className={styles.dropdownMenu}>
            {locations.map((loc, index) => (
              <LocationItem
                key={loc.CompanyID}
                name={loc.name}
                companyId={loc.CompanyID}
                setSelected={setSelected}
                onCheckboxClick={handleCheckboxClick}
                isChecked={array.includes(loc.CompanyID)}
              />
            ))}
            <li className={styles.menuCloseBtnItem}>
              <button
                className={styles.menuCloseBtn}
                onClick={() => setIsOpen(false)}
              >
                Close
              </button>
            </li>
          </ul>
        )}
      </div>

      {showSelected && (
        <SelectedLocations
          array={array}
          setArray={setArray}
          setLocation={setLocation}
          locations={locations}
        />
      )}
    </>
  );
};

export default LocationPicker;
