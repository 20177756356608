import { useEffect, useState, useMemo } from "react";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
//import useAxiosReportPrivate from "../../hooks/useAxiosReportPrivate";
const MouseTracker = ({ children }) => {
  const [startTime, setStartTime] = useState(null);
  const [endTime, setEndTime] = useState(null);
  const [mouseData, setMouseData] = useState([]);
  const axiosPrivate = useAxiosPrivate({ useAuthReport: true });

  useEffect(() => {
    const handleMouseMovee = () => {
      if (!startTime) {
        setStartTime(Date.now());
      } else {
        setEndTime(Date.now());
      }
    };

    document.addEventListener("mousemove", handleMouseMovee);

    const handleTimeout = () => {
      if (endTime && startTime) {
        const timeSpent = endTime - startTime;
        if (timeSpent >= 10000) {
          setMouseData((prevData) => [...prevData, timeSpent - 10000]); // subtract 10 seconds from timeSpent
          setStartTime(null);
          setEndTime(null);
        }
      }
    };

    const timeoutId = setTimeout(handleTimeout, 10000);

    return () => {
      document.removeEventListener("mousemove", handleMouseMovee);
      clearTimeout(timeoutId);
    };
  }, [startTime, endTime]);

  useEffect(() => {
    const intervalId = setInterval(() => {
      window.localStorage.setItem(
        "ActivityTimeData",
        JSON.stringify(mouseData)
      );
    }, 10000); // update cache 10 seconds after mouse stops moving

    const sendToServer = () => {
      if (mouseData.length == 2) {
        // console.log("should be going to server.");
        // console.log(mouseData);

        try {
          const sendUserActiveTime = async () => {
            await axiosPrivate
              .post("/api/report/activetime", {
                activetimes: mouseData,
              })
              .catch((error) => {
                console.log(error);
              });
          };
          sendUserActiveTime();
          setMouseData([]);
        } catch (err) {
          console.log("err: ", err);
        }
      } else {
        console.log("nothing in active time array. skipping.");
      }
    };

    const serverIntervalId = setInterval(sendToServer, 240000); // send to server every 2 minutes 120000

    return () => {
      clearInterval(intervalId);
      clearInterval(serverIntervalId);
    };
  }, [mouseData]);

  const setActivetime = () => {
    setMouseData([1254, 1254]);
    window.localStorage.setItem("ActivityTimeData", JSON.stringify(mouseData));
  };
  const sendToServer = () => {
    console.log("should be going to server.");
    console.log(mouseData);
  };
  //return <div>{children}</div>;
  return <></>;
};

export default MouseTracker;
