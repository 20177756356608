import { useState, useEffect } from "react";
import Button from "react-bootstrap/Button";
import Card from "react-bootstrap/Card";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import Table from "react-bootstrap/Table";
import Chartie from "./Chartie";
import SpeciesCateReportALL from "./SpeciesCateReportALL";

const UserDetails = ({ speciesList, locations }) => {
  const [details, setDetails] = useState();
  const [selectedSpeci, setSelectedSpeci] = useState("");
  const [speciesData, setSpeciesData] = useState();
  const [users, setUsers] = useState();
  const [selectedUser, setSelectedUser] = useState("");
  const axiosPrivate = useAxiosPrivate();
  const [selectedLocation, setSelectedLocation] = useState();
  const [activeTime, setActiveTime] = useState();
  const [pastDays, setPastDays] = useState([90, 60, 30, "ALL"]);
  const [days, setDays] = useState(90);
  const handleSelected = (e) => {
    setSelectedUser(e.target.value);
  };
  useEffect(() => {
    try {
      const getStats = async () => {
        const response = await axiosPrivate.get("/api/report/allDetails", {
          params: {
            useremail: selectedUser,
            pastdays: days === "ALL" ? 0 : days,
          },
        });
        console.log("user stuff: ", response.data);
        setDetails(response.data);
        const { species, totalCounts } = response.data[2].reduce(
          (acc, item) => {
            acc.species.push(item.species);
            acc.totalCounts.push(item.total_count);
            return acc;
          },
          { species: [], totalCounts: [] }
        );
        setSpeciesData({ species, totalCounts });
        setUsers(response.data[3]);

        setActiveTime(response.data[4]);
      };
      getStats();
    } catch (err) {}
  }, [selectedUser, days]);
  const convertTime = (totalTime) => {
    console.log("activeTime array: ", setActiveTime);
    const milliseconds = totalTime;

    // Convert to minutes
    const minutes = Math.floor(milliseconds / 60000);
    console.log(minutes); // output: 6

    // Convert to hours and minutes
    const hours = Math.floor(minutes / 60);
    const remainingMinutes = minutes % 60;
    console.log(hours + " hours " + remainingMinutes + " minutes"); // output: 0 hours 6 minutes
    return hours + " hours " + remainingMinutes + " minutes";
  };
  const handleSelectedSpecies = (e) => {
    setSelectedSpeci(e.target.value);
  };
  const handleSelectedLocation = (e) => {
    if (e.target.value === "allLocations") {
      console.log("selected report location: ALL locations selected");
      let allLocation = locations
        .map((location) => location.CompanyID)
        .join(", ");
      setSelectedLocation(allLocation);
      console.log("allLocation: ", allLocation);
    } else {
      console.log("selected report location: ", e.target.value);
      setSelectedLocation(e.target.value);
    }
  };
  const handlePastDays = (e) => {
    setDays(e.target.value);
  };
  return (
    <>
      <select onChange={handleSelected}>
        <option hidden>Select user</option>
        {users?.map((u) => (
          <option>{u.email}</option>
        ))}
      </select>
      <Card style={{ width: "18rem" }}>
        <Card.Body>
          <Card.Title>
            {details && details[0]?.[0]?.email
              ? details[0]?.[0]?.email
              : "Not loaded"}
          </Card.Title>
          <Card.Text>
            <div>Last Login</div>
            {details && details[0]?.[0]?.last_login
              ? details[0]?.[0]?.last_login
              : "Not loaded"}
            <div>Last off type</div>
            <div>Total time</div>
          </Card.Text>
        </Card.Body>
      </Card>
      {speciesData && (
        <Chartie
          title={`${details[0]?.[0]?.email} Species view`}
          dataView={speciesData}
          labelTitle={`${details[0]?.[0]?.email} Species view`}
        />
      )}
      <select style={{ marginRight: "25px" }} onChange={handleSelectedLocation}>
        <option hidden>Select Location</option>
        <option value="allLocations" key="allLocations">
          All
        </option>
        {locations?.map((loc) => (
          <option value={loc.CompanyID} key={loc.CompanyID}>
            {loc.name}
          </option>
        ))}
      </select>
      <select onChange={handleSelectedSpecies}>
        <option hidden>Select Species</option>
        {speciesList?.map((s) => (
          <option value={s.ddSpeciesCode} key={s.ddTitle}>
            {s.ddTitle}
          </option>
        ))}
      </select>
      <SpeciesCateReportALL
        selectedSpecies={selectedSpeci}
        selectedUser={selectedUser}
        selectedLocation={selectedLocation}
      />

      <h4>User Active Time records</h4>
      <select onChange={handlePastDays}>
        {pastDays?.map((days) => (
          <option value={days} key={days}>
            {days} days
          </option>
        ))}
      </select>
      <Table style={{ margin: "0px" }} striped bordered hover>
        <thead>
          <tr>
            <th>Email</th>
            <th>Total Time</th>
            <td>Date</td>
          </tr>
        </thead>
        <tbody>
          {activeTime &&
            activeTime?.map((aT) => (
              <tr>
                <td>{aT.email}</td>
                <td>{convertTime(aT.totalTime)}</td>
                <td>{aT.activeDay}</td>
              </tr>
            ))}
        </tbody>
      </Table>
      <h4 style={{ marginTop: "3.0rem" }}>User login records</h4>

      <Table striped bordered hover>
        <thead>
          <tr>
            <th>Record ID</th>
            <th>email</th>
            <th>loginDate</th>
            <th>logoutDate</th>
            <th>logoutBy</th>
            <th>eventDate</th>
            <th>logs</th>
          </tr>
        </thead>
        <tbody>
          {details &&
            details[1]?.map((d) => (
              <tr>
                <td>{d.recID}</td>
                <td>{d.email}</td>
                <td>{d.loginDate}</td>
                <td>{d.logoutDate}</td>
                <td>{d.logoutBy}</td>
                <td>{d.eventDate}</td>
                <td>{d.loginSuccessful}</td>
              </tr>
            ))}
        </tbody>
      </Table>
    </>
  );
};

export default UserDetails;
