import { createContext, useState } from "react";

const DetailContext = createContext({});

export const DetailProvider = ({ children }) => {
  const [details, setDetails] = useState({});

  return (
    <DetailContext.Provider value={{ details, setDetails }}>
      {children}
    </DetailContext.Provider>
  );
};

export default DetailContext;
