import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { AuthProvider } from "./context/AuthProvider";
import App from "./App";
import "./index.css";
import "../src/style.scss";
import { DetailProvider } from "./context/DetailProvider";
import { LocationProvider } from "./context/LocationProvider";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <BrowserRouter>
    <AuthProvider>
      <DetailProvider>
        <LocationProvider>
          <Routes>
            <Route path="/*" element={<App />} />
          </Routes>
        </LocationProvider>
      </DetailProvider>
    </AuthProvider>
  </BrowserRouter>
);
