import styles from "./PageTitle.module.css";
import { useParams } from "react-router-dom";

const Title = ({
  Title,
  SubTitle,
  CompanyName,
  // SaleRep: { Name, Number, Email },
  Color = "#18191a",
}) => {
  const { SpeciesCode } = useParams();
  return (
    <>
      {CompanyName ? (
        <>
          <div
            style={{
              backgroundImage:
                "linear-gradient( rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5) ), url('/images/244.jpg')",
              height: "164px",
              backgroundSize: "contain",
            }}
          >
            <div style={{ padding: "35px" }}>
              <h1 style={{ color: "white", textAlign: "start" }}>
                {CompanyName}
              </h1>
              <div className={styles.fadeRule}></div>
              <div
                style={{
                  textAlign: "center",
                  width: "250px",
                  height: "50PX",
                  backgroundColor: "rgba(0,0,0,0.5)",
                }}
              >
                <p style={{ color: Color, fontSize: 22 }}>{Title}</p>
              </div>
            </div>
          </div>
        </>
      ) : (
        <>
          <h5>{SubTitle}</h5>
          <h1>{Title}</h1>

          <div className={styles.fadeRule}></div>
        </>
      )}
    </>
  );
};

export default Title;
