import { useState, useEffect } from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Bar } from "react-chartjs-2";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

const SpeciesCateReportALL = ({
  selectedSpecies,
  color,
  selectedUser,
  selectedLocation,
}) => {
  const axiosPrivate = useAxiosPrivate();
  const [speciesCate, setSpeciestCate] = useState();
  useEffect(() => {
    try {
      const getSpeciesStats = async () => {
        const response = await axiosPrivate.get(
          "/api/report/categorybyspecies",
          {
            params: {
              selectedspecies: selectedSpecies,
              selecteduser: selectedUser,
              selectedlocation: selectedLocation,
            },
          }
        );
        console.log("categorybyspecies: ", response.data);
        setSpeciestCate(response.data[0]);
      };
      getSpeciesStats();
    } catch (err) {
      console.log("err: ", err);
    }
  }, [selectedSpecies, selectedUser, selectedLocation]);
  const options = {
    responsive: true,
    animation: {
      duration: 0, // general animation time
    },
    hover: {
      animationDuration: 0, // duration of animations when hovering an item
    },
    responsiveAnimationDuration: 0, // animation duration after a resize
    plugins: {
      legend: {
        position: "top",
      },
      title: {
        display: true,
        text: ["Category by Species"],
      },
    },
    scales: {
      y: {
        ticks: {
          precision: 0,
        },
      },
    },
  };
  const labels = speciesCate?.categories;
  const data = {
    labels,
    datasets: [
      {
        label: ["Total view of categories by species"],
        data: speciesCate?.totalCounts,
        backgroundColor: "rgba(48, 161, 61, 0.5)",
      },
    ],
  };
  return (
    <div style={{ width: "800px", height: "500px" }}>
      <div style={{ position: "absolute", marginTop: "30px" }}>Views</div>
      <Bar options={options} data={data} />
      <div
        style={{
          alignItems: "center",
        }}
      >
        Category Names
      </div>
    </div>
  );
};

export default SpeciesCateReportALL;
