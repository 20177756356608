import { useNavigate } from "react-router-dom";
import { useState, useEffect } from "react";
import { SpeciesList } from "../components/SpeciesList";

import PageTitle from "../components/PageTitle";
import useAxiosPrivate from "../hooks/useAxiosPrivate";

const DetailsPage = () => {
  const [species, setSpecies] = useState();
  const axiosPrivate = useAxiosPrivate();
  const history = useNavigate();
  useEffect(() => {
    const controller = new AbortController();
    const getSpecies = async () => {
      try {
        const response = await axiosPrivate.get("/api/species/details", {
          signal: controller.signal,
        });
        setSpecies(response.data.recordset);
        console.log(response);
      } catch (error) {
        console.log("error");
        console.log(error);
      }
    };
    getSpecies();
  }, []);
  const goToSpecies = (speciesId, detailType) => {
    history(`/species/${speciesId}/${detailType}`);
  };
  return (
    <>
      <div className="mx-5 mt-4"></div>
      {species ? (
        <SpeciesList species={species} onClickSpecies={goToSpecies} />
      ) : (
        <p>Loading...</p>
      )}
    </>
  );
};

export default DetailsPage;
