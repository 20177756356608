import { useState } from "react";
import CreateUser from "../components/CreateUser";
import UserList from "../components/UserList";
import PasswordReset from "../components/PasswordReset";

const AdminPage = () => {
  const [listUpdated, setListUpdated] = useState("false");
  return (
    <>
      <CreateUser setListUpdated={setListUpdated} listUpdated={listUpdated} />
      <PasswordReset />
      <UserList setListUpdated={setListUpdated} listUpdated={listUpdated} />
    </>
  );
};

export default AdminPage;
